import React, { useState, Suspense } from "react";

import { auth } from "./firebase";

function ForgotPanel(props) {
  // Forgot Password
  const [signUpEmail, setSignUpEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const onForgotChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userSignUpEmail") {
      setSignUpEmail(value);
    }
  };

  //Sign Up
  const forgotPassword = (event, signUpEmail) => {
    event.preventDefault();
    if (!signUpEmail) {
      setError("Error email cannot be empty");
    } else {
      auth
        .sendPasswordResetEmail(signUpEmail)
        .then(function () {
          // Email sent.
          setSuccess("Password Reset request has been set");
        })
        .catch(function (error) {
          // An error happened.
          setError("An Error has occured");
        });
    }
  };

  return (
    <>
      <main id="main">
        <div className="page" id="top">
          <section class="page-section bg-dark footer pb-60">
            <a href="/">
              <span>
                <i class="fa fa-angle-left "></i> Back to home
              </span>
            </a>

            <div class="container">
              <div
                class="local-scroll mb-30 wow fadeInUp"
                data-wow-duration="1.5s"
              >
                <h1 class="hs-line-11 font-alt mb-20 mb-xs-0">
                  Forgot Password
                </h1>
                <div class="hs-line-4 font-alt">
                  Enter your email address to send forgot password request
                </div>
              </div>
            </div>
          </section>

          <section class="page-section">
            <div class="container relative">
              <div class="tab-content tpl-minimal-tabs-cont section-text">
                <div class="tab-pane fade in active" id="mini-one">
                  <div className="row">
                    <div className="col-md-4 col-md-offset-4">
                      <form className="form contact-form" id="login-form">
                        <div className="clearfix">
                          <div className="form-group">
                            <input
                              type="email"
                              name="userSignUpEmail"
                              id="userSignUpEmail"
                              value={signUpEmail}
                              className="input-md round form-control"
                              placeholder="Email"
                              onChange={(event) => onForgotChangeHandler(event)}
                            />
                          </div>
                        </div>

                        <div className="clearfix">
                          <div className="cf-left-col">
                            <div className="form-tip pt-20"></div>
                          </div>

                          <div className="pt-10">
                            <button
                              className="submit_btn btn btn-mod btn-medium btn-round btn-full"
                              id="reg-btn"
                              onClick={(event) => {
                                forgotPassword(event, signUpEmail);
                              }}
                            >
                              Forgot
                            </button>
                            <p className="error text-danger center-align"></p>
                          </div>

                          {error !== null && (
                            <div className="py-4 bg-red-600 w-full text-danger text-center mb-3">
                              {error}
                            </div>
                          )}

                          {success !== null && (
                            <div className="py-4 bg-red-600 w-full text-siccess text-center mb-3">
                              {success}
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>

      <footer class="page-section bg-dark footer pb-60">
        <div class="container">
          <div class="local-scroll mb-30 wow fadeInUp" data-wow-duration="1.5s">
            <a href="#top">
              <img
                src="images/logos/wave-white.png"
                width="180"
                height="180"
                alt="Company logo"
              />
              <span class="sr-only">Scroll to the top of the page</span>
            </a>
          </div>

          <div class="footer-social-links mb-110 mb-xs-60 white">
            <div class="sr-only">Our profiles in social media:</div>
            <a
              href="https://www.facebook.com/"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-facebook white"></i>
              <span class="sr-only white">Facebook profile</span>
            </a>
            <a
              href="https://twitter.com/"
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-twitter white"></i>
              <span class="sr-only white">Twitter profile</span>
            </a>
            <a
              href="https://instagram.com/"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-instagram white"></i>
              <span class="sr-only white">Instagram profile</span>
            </a>
            <a
              href="https://youtube.com/channel/"
              title="YouTube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-youtube-play white"></i>
              <span class="sr-only white">YouTube profile</span>
            </a>

            <a
              href="https://t.me/TheFxWave"
              title="Telegram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-telegram white"></i>
              <span class="sr-only white">Telegram profile</span>
            </a>
          </div>

          <div class="footer-text">
            <div class="footer-copy font-alt">&copy; FX Wave 2021 .</div>

            <div class="footer-made">Ride The Markets Wave</div>
          </div>
        </div>

        <div class="local-scroll">
          <a href="#top" class="link-to-top">
            <i class="fa fa-caret-up"></i>
          </a>
        </div>
      </footer>
    </>
  );
}

export default function Forgot() {
  return (
    <Suspense fallback={"loading user"}>
      <ForgotPanel />
    </Suspense>
  );
}

import React, { useState, useEffect, Suspense, useRef } from "react";
import { AuthCheck } from "reactfire";
import { auth, db } from "../firebase";

import AuthHome from "./AuthHome";
import Footer from "./Footer";

function DiscordPopup() {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user !== null) {
        db.collection("users")
          .doc(user.uid)
          .onSnapshot((doc) => setData(doc.data()));
      }
    });

    return unsubscribe;
  }, []);

  const [error, setError] = useState("");
  const fnameRef = useRef();
  const lnameRef = useRef();
  const discorduserRef = useRef();
  const [isDisabled, setIsDisabled] = useState(true);

  function onChange() {
    if (discorduserRef.current.value) {
      setIsDisabled(false);
    }
  }

  async function handelSubmit(e) {
    e.preventDefault();

    if (discorduserRef.current.value === "") {
      discorduserRef.current.value = data.discordUser;
    }

    var newLinkUpdate = db.collection("users").doc(currentUser.uid);

    // Set the "capital" field of the city 'DC'
    return newLinkUpdate
      .update({
        discordUser: discorduserRef.current.value,
      })
      .then(() => {
        setError("New profile updates have been processed");
        setIsDisabled(true);
      })
      .catch((error) => {
        // The document probably doesn't exist.
        setError("Error updating profile: ", error);
      });
  }

  return (
    <>
      {" "}
      <AuthCheck fallback={<AuthHome />}>
        <div className="discord-window">
          <div className="row discord-overflow-transparent"></div>
          <div className="row discord-overflow">
            <section className="page-section" id="details">
              <div className="container relative">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2">
                    <div className="work-detail">
                      <span>
                        <h5 className="font-alt mt-0 mb-20">
                          Your discord username is empty please update to
                          continue
                        </h5>
                      </span>
                      <div className="work-full-detail">
                        <form
                          className="form contact-form"
                          id="login-form"
                          onSubmit={handelSubmit}
                          onChange={onChange}
                        >
                          <p>
                            <strong>Discord Username:</strong>

                            <div className="form-group" id="discorduser">
                              <input
                                type="text"
                                className="input-md round form-control"
                                ref={discorduserRef}
                                placeholder={data && data.discordUser}
                              />
                            </div>
                          </p>
                          <div className="clearfix">
                            <div className="cf-left-col">
                              <div className="form-tip pt-20"></div>
                            </div>

                            <div className="pt-10">
                              {!isDisabled && (
                                <button
                                  className="discord-overflow-btn-update "
                                  id="reg-btn"
                                >
                                  Update Profile
                                </button>
                              )}
                              {isDisabled && (
                                <button
                                  disabled
                                  className="discord-overflow-btn"
                                  id="reg-btn"
                                >
                                  Enter your discord name to unlock
                                </button>
                              )}
                              <p className="error text-danger center-align"></p>
                            </div>
                            {error !== null && (
                              <div className="py-4 bg-red-600 w-full text-danger text-center mb-3">
                                {error}
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="row discord-overflow-transparent"></div>
        </div>
      </AuthCheck>
    </>
  );
}

export default function DiscordPreview() {
  return (
    <Suspense
      fallback={
        <div className="page-loader">
          <div className="loader">Loading...</div>
        </div>
      }
    >
      <DiscordPopup />
    </Suspense>
  );
}

import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useUser, AuthCheck } from "reactfire";

import { db } from "./firebase";
import AuthHome from "./components/AuthHome";

function AdminUserData(props) {
  let jsarray = JSON.parse(sessionStorage.getItem("jsArray"));
  //console.log(jsarray);

  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  // Subscribe to the user's data in Firestore
  useEffect(() => {
    const unsubscribe = db
      .collection("users")
      .doc(props.user.uid)
      .onSnapshot((doc) => setData(doc.data()));
    return () => unsubscribe();
  }, [props.user]);

  if (data && data.userType) {
    if (data && data.userType != "Admin") {
      window.location.href = "/profile";
    }
  }

  //=========================== Create new discord link //===========================

  const [newLink, setNewLink] = useState("");

  const onLinkChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "new-link") {
      setNewLink(value);
    }
  };

  const newLinkReq = async (event, newLink) => {
    event.preventDefault();

    const discordLinks = db
      .collection("links")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //console.log(doc.id, " => ", doc.data());

          var newLinkUpdate = db.collection("links").doc(doc.id);

          // Set the "capital" field of the city 'DC'
          return newLinkUpdate
            .update({
              Content: newLink,
            })
            .then(() => {
              setNewLink("");
              setSuccess("The new link has been posted");
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        });
        return () => discordLinks();
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  //=========================== Fetch Users //===========================

  const [listOfUsers, setListOfUsers] = useState([]);
  const [lastDoc, setLastDoc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [queryType, setQueryType] = useState("");

  if (queryType === "") {
    setQueryType(db.collection("users").orderBy("signupDate", "asc"));
  }

  const usersRef = queryType;

  function paidUsers(e) {
    e.preventDefault();

    setListOfUsers([]);
    setLastDoc([]);
    setIsEmpty(false);

    setQueryType(db.collection("users").where("billed", "==", "Yes"));

    db.collection("users")
      .where("billed", "==", "Yes")
      .limit(10)
      .get()
      .then((collections) => {
        updateState(collections);
      });
  }

  function withdrawRequests(e) {
    e.preventDefault();

    setListOfUsers([]);
    setLastDoc([]);
    setIsEmpty(false);

    setQueryType(
      db.collection("withdrawalRequests").where("status", "==", "Pending")
    );

    db.collection("withdrawalRequests")
      .where("status", "==", "Pending")
      .limit(10)
      .get()
      .then((collections) => {
        updateState(collections);
      });
  }

  function relationships(e) {
    e.preventDefault();

    setListOfUsers([]);
    setLastDoc([]);
    setIsEmpty(false);

    setQueryType(db.collection("refererRelationship"));

    db.collection("refererRelationship")
      .limit(10)
      .get()
      .then((collections) => {
        updateState(collections);
      });
  }

  function allUsers(e) {
    e.preventDefault();

    setListOfUsers([]);
    setLastDoc([]);
    setIsEmpty(false);

    setQueryType(db.collection("users"));

    db.collection("users")
      .limit(10)
      .get()
      .then((collections) => {
        updateState(collections);
      });
  }

  const updateState = (collections) => {
    const isCollectionEmpty = collections.size === 0;
    if (!isCollectionEmpty) {
      const usersLists = collections.docs.map((users) => users.data());
      const lastDoc = collections.docs[collections.docs.length - 1];

      setListOfUsers((listOfUsers) => [...listOfUsers, ...usersLists]);
      setLastDoc(lastDoc);
    } else {
      setIsEmpty(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    usersRef

      .limit(10)
      .get()
      .then((collections) => {
        updateState(collections);
      });
  }, []);

  const fetchMore = () => {
    setLoading(true);
    usersRef

      .startAfter(lastDoc)
      .limit(20)
      .get()
      .then((collections) => {
        updateState(collections);
      });
  };

  const openUser = (e) => {
    e.preventDefault();
    //alert("User Clicked");

    //Cancel user modal and additional info pop up
  };

  

  return (
    <>
      <section className="page-section" id="withdraw">
        <div className="container relative">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-2 align-center">
              <h4 className="font-alt mb-70 mb-sm-40">Enter todays link</h4>

              <form className="form contact-form" id="withdraw-form">
                <div className="clearfix">
                  <div className="form-group">
                    <input
                      type="text"
                      name="new-link"
                      id="new-link"
                      className="input-md round form-control"
                      placeholder="Enter a discord link"
                      required
                      aria-required="true"
                      value={newLink}
                      onChange={(event) => onLinkChangeHandler(event)}
                    />
                  </div>
                </div>

                <div className="clearfix">
                  <div className="cf-center-col">
                    <div className="align-center pt-10">
                      <button
                        className="submit_btn btn btn-mod btn-medium btn-round"
                        id="request-withdraw-btn"
                        onClick={(event) => newLinkReq(event, newLink)}
                      >
                        Update Discord Link
                      </button>
                    </div>

                    {error !== null && (
                      <div className="py-4 bg-red-600 w-full text-danger text-center mb-3">
                        {error}
                      </div>
                    )}

                    {success !== null && (
                      <div className="py-4 bg-red-600 w-full text-siccess text-center mb-3">
                        {success}
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <hr className="mt-0 mb-0" />

      <section className="page-section">
        <div className="container relative">
          <h2 className="section-title font-alt mb-70 mb-sm-40">Users</h2>

          <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
              <ul className="nav nav-tabs tpl-tabs animate">
                <li className="active">
                  <a
                    href="#one"
                    data-toggle="tab"
                    onClick={(e) => paidUsers(e)}
                  >
                    Paid
                  </a>
                </li>
                <li>
                  <a
                    href="#two"
                    className="bg-warning"
                    data-toggle="tab"
                    onClick={(e) => withdrawRequests(e)}
                  >
                    Withdraw Requests
                  </a>
                </li>
                <li>
                  <a href="#three" className="bg-danger" data-toggle="tab">
                    Cancel Requests
                  </a>
                </li>
                <li>
                  <a
                    href="#fourth"
                    data-toggle="tab"
                    onClick={(e) => relationships(e)}
                  >
                    Relationships
                  </a>
                </li>
                <li>
                  <a
                    href="#fifth"
                    data-toggle="tab"
                    onClick={(e) => allUsers(e)}
                  >
                    All Users
                  </a>
                </li>
              </ul>

              <div className="tab-content tpl-tabs-cont section-text">
                <div className="tab-pane fade in active" id="one">
                  <h2 className="section-title font-alt mb-70 mb-sm-40">
                    Paid Users
                  </h2>

                  <table role="table">
                    <thead role="rowgroup">
                      <tr role="row">
                        <th role="columnheader">Name</th>
                        <th role="columnheader">Email</th>
                        <th role="columnheader">Discord Username</th>
                        <th role="columnheader">Available to Withdraw</th>
                        <th role="columnheader">Stripe ID</th>
                        <th role="columnheader">Total Referred</th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      {listOfUsers &&
                        listOfUsers.map((item, index) => {
                          return (
                            <>
                              <tr role="row" key={index}>
                                <td role="cell">
                                  {item.fname} {item.lname}
                                </td>

                                <td role="cell">{item.email}</td>
                                <td role="cell">{item.discordUser}</td>
                                <td role="cell">{item.avaibleWidthdrawl}</td>
                                <td role="cell"> {item.stripeCustomerId}</td>
                                <td role="cell"> {item.totalRefered}</td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>

                  {loading && <h1>Loading ..... </h1>}
                  {!loading && !isEmpty && (
                    <button
                      className="btn btn-warning w-100 mt-3"
                      onClick={fetchMore}
                    >
                      More
                    </button>
                  )}
                  {isEmpty && <h1>There are no more docs</h1>}
                </div>

                <div className="tab-pane fade" id="two">
                  <h2 className="section-title font-alt mb-70 mb-sm-40">
                    Withdrawl Requests
                  </h2>
                  {listOfUsers.map((item, index) => {
                    return (
                      <a href="#" onClick={(e) => openUser(e)}>
                        <div className="row" key={index}>
                          <div className="col-sm-4 mb-40">
                            <div className="text">
                              <h5 className="uppercase">User</h5>
                              {item.user}
                            </div>
                          </div>

                          <div className="col-sm-4 mb-40">
                            <div className="text">
                              <h5 className="uppercase">Amount</h5>$
                              {item.amount}
                            </div>
                          </div>

                          <div className="col-sm-4 mb-40">
                            <div className="text">
                              <h5 className="uppercase">Status</h5>
                              {item.status}
                            </div>
                          </div>
                        </div>
                      </a>
                    );
                  })}
                  {loading && <h1>Loading ..... </h1>}
                  {!loading && !isEmpty && (
                    <button
                      className="btn btn-warning w-100 mt-3"
                      onClick={fetchMore}
                    >
                      More
                    </button>
                  )}
                  {isEmpty && <h1>There are no more docs</h1>}
                </div>

                <div className="tab-pane fade" id="three">
                  Pellentesque sed vehicula velit, vitae vulputate velit. Morbi
                  nec porta augue, et dignissim enim. Vivamus suscipit, lorem
                  vitae rhoncus pharetra, erat nisl scelerisque magna, ut mollis
                  dui eros eget libero. Vivamus ut ornare tellus. Aliquam tortor
                  leo, pharetra non congue sit amet, bibendum sit amet enim.
                  Nullam sit amet malesuada justo.
                </div>

                <div className="tab-pane fade" id="fourth">
                  <h2 className="section-title font-alt mb-70 mb-sm-40">
                    User Relationships
                  </h2>
                  {listOfUsers &&
                    listOfUsers.map((item, index) => {
                      return (
                        <a href="#" onClick={(e) => openUser(e)}>
                          <div className="row" key={index}>
                            <div className="col-sm-4 mb-40">
                              <div className="text">
                                <h5 className="uppercase">Master User</h5>
                                {item.masterReferer}
                              </div>
                            </div>

                            <div className="col-sm-4 mb-40">
                              <div className="text">
                                <h5 className="uppercase">Child User</h5>
                                {item.childEmail}
                              </div>
                            </div>

                            <div className="col-sm-4 mb-40">
                              <div className="text">
                                <h5 className="uppercase">Billing Status</h5>
                                {item.activeBilling}
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  {loading && <h1>Loading ..... </h1>}
                  {!loading && !isEmpty && (
                    <button
                      className="btn btn-warning w-100 mt-3"
                      onClick={fetchMore}
                    >
                      More
                    </button>
                  )}
                  {isEmpty && <h1>There are no more docs</h1>}
                </div>

                <div className="tab-pane fade" id="fifth">
                  <h2 className="section-title font-alt mb-70 mb-sm-40">
                    All Users
                  </h2>
                  {listOfUsers &&
                    listOfUsers.map((item, index) => {
                      return (
                        <a href="#" onClick={(e) => openUser(e)}>
                          <div className="row" key={index}>
                            <div className="col-sm-3 mb-40">
                              <div className="text">
                                <h5 className="uppercase">Name</h5>
                                {item.fname} {item.lname}
                              </div>
                            </div>

                            <div className="col-sm-3 mb-40">
                              <div className="text">
                                <h5 className="uppercase">Email</h5>
                                {item.email}
                              </div>
                            </div>

                            <div className="col-sm-3 mb-40">
                              <div className="text">
                                <h5 className="uppercase">Billing Status</h5>
                                {item.billed}
                              </div>
                            </div>

                            <div className="col-sm-3 mb-40">
                              <div className="text">
                                <h5 className="uppercase">stripeCustomerId</h5>
                                {item.stripeCustomerId}
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  {loading && <h1>Loading ..... </h1>}
                  {!loading && !isEmpty && (
                    <button
                      className="btn btn-warning w-100 mt-3"
                      onClick={fetchMore}
                    >
                      More
                    </button>
                  )}
                  {isEmpty && <h1>There are no more docs</h1>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
    </>
  );
}

function AdminPage(props) {
  const user = useUser();
  return (
    <>
      <AuthCheck fallback={<AuthHome />}>
        <section
          className="page-section bg-dark footer pb-60"
          style={{
            backgroundImage: "url(images/full-width-images/section-bg-1.jpg)",
          }}
        >
          <div className="container">
            <div
              className="local-scroll mb-30 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <h1 className="hs-line-11 font-alt mb-20 mb-xs-0">
                Admin Back office
              </h1>
              <div className="hs-line-4 font-alt"></div>
            </div>
            <div
              className="local-scroll mb-30 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <div class="mod-breadcrumbs font-alt white align-center">
                <a href="/profile" class="btn btn-mod btn-w btn-circle">
                  <i class="fa   fa-user" aria-hidden="true"></i>
                  Back To Profile
                </a>
              </div>
            </div>
          </div>
        </section>

        {user?.uid && <AdminUserData user={user} />}
      </AuthCheck>
    </>
  );
}

export default function AdminProfile() {
  return (
    <Suspense
      fallback={
        <div classname="page-loader">
          <div classname="loader">Loading...</div>
        </div>
      }
    >
      <AdminPage />
    </Suspense>
  );
}

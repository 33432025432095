import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../firebase";

function login(email, password) {
  return auth.signInWithEmailAndPassword(email, password);
}

export default function SignIn() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handelSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      //history.push("/Dashboard");
    } catch {
      setError("Failed to sign in");
    }

    setLoading(false);
  }
  return (
    <>
      <form
        className="form contact-form"
        id="login-form"
        onSubmit={handelSubmit}
      >
        <div className="clearfix">
          <div className="form-group" id="email">
            <input
              type="email"
              className="input-md round form-control"
              placeholder="Enter your Email Address"
              ref={emailRef}
              required
            />
          </div>

          <div className="form-group" id="password">
            <input
              type="password"
              className="input-md round form-control"
              placeholder="Password"
              ref={passwordRef}
              required
            />
          </div>
        </div>

        <div className="clearfix">
          <div className="cf-left-col">
            <div className="form-tip pt-20"></div>
          </div>

          <div className="pt-10">
            <button
              className="submit_btn btn btn-mod btn-medium btn-round btn-full"
              id="reg-btn"
            >
              Login
            </button>
            <p className="error text-danger center-align"></p>
          </div>
          {error !== null && (
            <div className="py-4 bg-red-600 w-full text-danger text-center mb-3">
              {error}
            </div>
          )}

          <div className="pt-10">
            <div class="form-tip pt-20 ">
              <a href="/Forgot">Forgot Password?</a>
            </div>
            <p className="error text-danger center-align"></p>
          </div>
        </div>
      </form>
    </>
  );
}

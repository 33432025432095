import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { auth, db } from "../firebase";

export default function NavBar() {
  return (
    <nav className="main-nav dark transparent stick-fixed">
      <div className="full-wrapper relative clearfix">
        <div className="nav-logo-wrap local-scroll">
          <h1 className="logo hs-line-1 font-alt mb-80 mb-xs-30 mt-70 mt-sm-0">
            Wave
          </h1>
        </div>

        <div className="inner-nav desktop-nav" id="collapsibleNavbar">
          <ul className="clearlist scroll-nav local-scroll">
            <li>
              <Link
                to="/"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                Home
              </Link>
            </li>

            <li>
              <Link
                to="/#about"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                About
              </Link>
            </li>

            <li>
              <Link
                to="/#pricing"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                Pricing
              </Link>
            </li>

            <li>
              <Link
                to="/#services"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                Services
              </Link>
            </li>

            <li>
              <Link
                to="/#whychooseus"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                Why Choose us?
              </Link>
            </li>
            <li>
              <Link
                to="/#testimonials"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                Testimonials
              </Link>
            </li>
            <li>
              <a
                href=""
                to="/#news"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                News
              </a>
            </li>
            <li>
              <Link
                to="/#contact"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                Contacts
              </Link>
            </li>
            <li>
              <a href="/profile">Profile</a>
            </li>
          </ul>
        </div>

        <div
          className="mobile-nav"
          role="button"
          tabindex="0"
          data-toggle="collapse"
          data-target="#collapsibleNavbar"
        >
          <i className="fa fa-bars"></i>
          <span className="sr-only">Menu</span>
        </div>
      </div>
    </nav>
  );
}

import React, { useState, Suspense } from "react";

import { auth, db } from "./firebase";

export function SignIn() {
  //Login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  //Login
  const signInWithEmailAndPassword = async (event, email, password) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      setError(error);
    });
  };

  //Sign up
  const [signUpEmail, setSignUpEmail] = useState("");
  const [signUpPwd, setSignUpPwd] = useState("");
  const [signUpFname, setSignUpFname] = useState("");
  const [signUpLname, setSignUpLname] = useState("");
  const [signUpRefCode, setSignUpRefCode] = useState("");

  //Copy URL
  if (window.$name) {
    var modifiedURL = window.$name;
  }

  const onSignUpChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userSignUpEmail") {
      setSignUpEmail(value);
    } else if (name === "userSignUpPassword") {
      setSignUpPwd(value);
    } else if (name === "userSignUpFName") {
      setSignUpFname(value);
    } else if (name === "userSignUpLName") {
      setSignUpLname(value);
    } else if (name === "userSignUpRefCode") {
      setSignUpRefCode(value);
    }
  };

  //Sign Up
  const signUpWithEmailAndPassword = (
    event,
    signUpEmail,
    signUpPwd,
    signUpFname,
    signUpLname,
    signUpRefCode
  ) => {
    event.preventDefault();
    if (!signUpFname) {
      setError("Error first name cannot be empty");
    } else if (!signUpLname) {
      setError("Error last name cannot be empty");
    } else if (signUpPwd.length < 8) {
      setError("Error your password cannot be less than 8 charaters");
    } else {
      if (window.$name) {
        var modifiedURL = window.$name;
        //if name like fxwave
        var transformReferalCode =
          "https://thefxwave.com#" + modifiedURL.toLowerCase();
      } else {
        if (!signUpRefCode) {
          var transformReferalCode = "NA";
        } else {
          var transformReferalCode = signUpRefCode.toLowerCase();
        }
      }

      var transformEmail = signUpEmail.toLowerCase();
      //TODO Check for in correct referal code
      //TODO make sure users cant referrer them selves

      auth
        .createUserWithEmailAndPassword(transformEmail, signUpPwd)
        .then((cred) => {
          return db
            .collection("users")
            .doc(cred.user.uid)
            .set({
              fname: signUpFname,
              lname: signUpLname,
              email: transformEmail,
              userType: "User",
              referalCode: "thefxwave.com#" + transformEmail,
              signupDate: new Date(),
              billed: "No",
              totalRefered: 0,
              referalCodeUsed: transformReferalCode,
              referalChecks: 0,
              avaibleWidthdrawl: 0,
            });
        })
        .catch((error) => {
          setError("Error signing in with password and email!" + error);
          console.error("Error signing in with password and email", error);
        });
    }
  };

  return (
    <>
      <main id="main">
        <div className="page" id="top">
          <section class="page-section bg-dark footer pb-60">
            <a href="/">
              <span>
                <i class="fa fa-angle-left "></i> Back to home
              </span>
            </a>

            <div class="container">
              <div
                class="local-scroll mb-30 wow fadeInUp"
                data-wow-duration="1.5s"
              >
                <h1 class="hs-line-11 font-alt mb-20 mb-xs-0">Join us</h1>
                <div class="hs-line-4 font-alt">
                  In order to purchase please login or register
                </div>
              </div>
            </div>
          </section>

          <section class="page-section">
            <div class="container relative">
              <div class="align-center mb-40 mb-xxs-30">
                <ul class="nav nav-tabs tpl-minimal-tabs">
                  <li class="active">
                    <a href="#mini-one" data-toggle="tab">
                      Registration
                    </a>
                  </li>

                  <li>
                    <a href="#mini-two" data-toggle="tab">
                      Login
                    </a>
                  </li>
                </ul>
              </div>
              <div class="tab-content tpl-minimal-tabs-cont section-text">
                <div class="tab-pane fade in active" id="mini-one">
                  <div className="row">
                    <div className="col-md-4 col-md-offset-4">
                      <form className="form contact-form" id="signup-form">
                        <div className="clearfix">
                          <div className="form-group">
                            <input
                              type="text"
                              name="userSignUpFName"
                              id="userSignUpFName"
                              value={signUpFname}
                              className="input-md round form-control"
                              placeholder="First Name"
                              onChange={(event) => onSignUpChangeHandler(event)}
                            />
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              name="userSignUpLName"
                              id="userSignUpLName"
                              value={signUpLname}
                              className="input-md round form-control"
                              placeholder="Last Name"
                              onChange={(event) => onSignUpChangeHandler(event)}
                            />
                          </div>

                          <div className="form-group">
                            <input
                              type="email"
                              name="userSignUpEmail"
                              id="userSignUpEmail"
                              value={signUpEmail}
                              className="input-md round form-control"
                              placeholder="Email"
                              onChange={(event) => onSignUpChangeHandler(event)}
                            />
                          </div>

                          <div className="form-group">
                            <input
                              type="password"
                              name="userSignUpPassword"
                              id="userSignUpPassword"
                              value={signUpPwd}
                              className="input-md round form-control"
                              placeholder="Password"
                              onChange={(event) => onSignUpChangeHandler(event)}
                            />
                          </div>
                          {(() => {
                            if (modifiedURL) {
                              return (
                                <>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="userSignUpRefCode"
                                      id="userSignUpRefCode"
                                      value={"fxswaymarket.com#" + modifiedURL}
                                      className="input-md round form-control"
                                      placeholder="Enter a referral Code"
                                      onChange={(event) =>
                                        onSignUpChangeHandler(event)
                                      }
                                    />
                                  </div>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="userSignUpRefCode"
                                      id="userSignUpRefCode"
                                      value={signUpRefCode}
                                      className="input-md round form-control"
                                      placeholder="Enter a referral Code"
                                      onChange={(event) =>
                                        onSignUpChangeHandler(event)
                                      }
                                    />
                                  </div>
                                </>
                              );
                            }
                          })()}
                        </div>

                        <div className="pt-10">
                          <button
                            className="submit_btn btn btn-mod btn-medium btn-round btn-full"
                            id="reg-btn"
                            onClick={(event) => {
                              signUpWithEmailAndPassword(
                                event,
                                signUpEmail,
                                signUpPwd,
                                signUpFname,
                                signUpLname,
                                signUpRefCode
                              );
                            }}
                          >
                            Register
                          </button>
                          <p className="error text-danger center-align"></p>
                        </div>
                        {error !== null && (
                          <div className="py-4 bg-red-600 w-full text-danger text-center mb-3">
                            {error}
                          </div>
                        )}
                        <div class="dark">
                          <p class="disclaimerblock">
                            Risk Disclaimer: Trading forex carries risk. Before
                            participating in the FX market, you should carefully
                            consider your investment objectives, and risk
                            appetite. Past performance does not guarantee future
                            results. You are investing at your own risk, you
                            assume full responsibility. All information we
                            provide is for educational purposes.
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="mini-two">
                  <div className="row">
                    <div className="col-md-4 col-md-offset-4">
                      <form className="form contact-form" id="login-form">
                        <div className="clearfix">
                          <div className="form-group">
                            <input
                              type="email"
                              name="userEmail"
                              id="userEmail"
                              value={email}
                              className="input-md round form-control"
                              placeholder="Enter your Email Address"
                              onChange={(event) => onChangeHandler(event)}
                              required
                            />
                          </div>

                          <div className="form-group">
                            <input
                              type="password"
                              name="userPassword"
                              id="userPassword"
                              className="input-md round form-control"
                              placeholder="Password"
                              onChange={(event) => onChangeHandler(event)}
                              required
                            />
                          </div>
                        </div>

                        <div className="clearfix">
                          <div className="cf-left-col">
                            <div className="form-tip pt-20"></div>
                          </div>

                          <div className="pt-10">
                            <button
                              className="submit_btn btn btn-mod btn-medium btn-round btn-full"
                              id="reg-btn"
                              onClick={(event) => {
                                signInWithEmailAndPassword(
                                  event,
                                  email,
                                  password
                                );
                              }}
                            >
                              Login
                            </button>
                            <p className="error text-danger center-align"></p>
                          </div>
                          {error !== null && (
                            <div className="py-4 bg-red-600 w-full text-danger text-center mb-3">
                              {error}
                            </div>
                          )}

                          <div className="pt-10">
                            <div class="form-tip pt-20 ">
                              <a href="/Forgot">Forgot Password?</a>
                            </div>
                            <p className="error text-danger center-align"></p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>

      <footer class="page-section bg-dark footer pb-60">
        <div class="container">
          <div class="local-scroll mb-30 wow fadeInUp" data-wow-duration="1.5s">
            <a href="#top">
              <img
                src="images/logos/wave-white.png"
                width="180"
                height="180"
                alt="Company logo"
              />
              <span class="sr-only">Scroll to the top of the page</span>
            </a>
          </div>

          <div class="footer-social-links mb-110 mb-xs-60 white">
            <div class="sr-only">Our profiles in social media:</div>
            <a
              href=""
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-facebook white"></i>
              <span class="sr-only white">Facebook profile</span>
            </a>
            <a
              href=""
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-twitter white"></i>
              <span class="sr-only white">Twitter profile</span>
            </a>
            <a
              href=""
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-instagram white"></i>
              <span class="sr-only white">Instagram profile</span>
            </a>
            <a
              href=""
              title="YouTube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-youtube-play white"></i>
              <span class="sr-only white">YouTube profile</span>
            </a>

            <a
              href="https://t.me/TheFxWave"
              title="Telegram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-telegram white"></i>
              <span class="sr-only white">Telegram profile</span>
            </a>
          </div>

          <div class="footer-text">
            <div class="footer-copy font-alt">&copy; FXWave 2021 .</div>

            <div class="footer-made">Ride The Markets Wave</div>
          </div>
        </div>

        <div class="local-scroll">
          <a href="#top" class="link-to-top">
            <i class="fa fa-caret-up"></i>
          </a>
        </div>
      </footer>

      <a
        href="https://t.me/FX_Wave_Support"
        target="_blank"
        rel="noopener noreferrer"
        class="support-button"
      >
        <i class="fa fa-telegram" aria-hidden="true"></i>
        <span>Help</span>
      </a>
    </>
  );
}

export function SignOut(props) {
  return (
    props.user && (
      <button
        className="submit_btn btn btn-mod btn-medium btn-round"
        onClick={() => auth.signOut()}
      >
        Sign Out
      </button>
    )
  );
}

export default function Customers() {
  return (
    <Suspense fallback={"loading user"}>
      <SignIn />
    </Suspense>
  );
}

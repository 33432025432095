import React, { useState, useEffect, Suspense } from "react";
import { fetchFromAPI } from "./helpers";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useUser, AuthCheck } from "reactfire";
import { Link } from "react-router-dom";

import { db, auth } from "./firebase";
import { SignIn } from "./Login";

import Footer from "./components/Footer";

function SubscribeToPlan(props) {
  const stripe = useStripe();
  const elements = useElements();
  const user = useUser();
  const [data, setData] = useState({});

  const [plan, setPlan] = useState();
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [planName, setPlanName] = useState();

  // Get current subscriptions on mount
  useEffect(() => {
    getSubscriptions();

    const unsubscribe = auth.onAuthStateChanged((user) => {
      setLoading(false);

      if (user !== null) {
        db.collection("users")
          .doc(user.uid)
          .onSnapshot((doc) => setData(doc.data()));
      }
    });

    return unsubscribe;
  }, [user]);

  // Fetch current subscriptions from the API
  const getSubscriptions = async () => {
    if (user) {
      const subs = await fetchFromAPI("subscriptions", { method: "GET" });
      setSubscriptions(subs);
    }
  };

  const updatePlanDetails = async () => {
    db.collection("users").doc(user.uid).update({
      billed: "Yes",
    });
  };

  const planDetailsMonthly = () => {
    setPlanName("Monthly");
    setPlan("price_1LX2cJEuudGs5blLSb4edkZ4");
  };

  const planDetailsYearly = () => {
    setPlanName("Yearly");
    setPlan("price_1LX2d8EuudGs5blLPfmmIK09");
  };

  // const planDetailsOneTime = async () => {
  //   setPlanName("One Time");
  //   setPlan("price_1NxEO9EuudGs5blLqofXZ3kN");
  // };

  // Handle the submission of card details
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const cardElement = elements.getElement(CardElement);

    // Create Payment Method
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      alert(error.message);
      setLoading(false);
      return;
    }

    // Create Subscription on the Server
    const subscription = await fetchFromAPI("subscriptions", {
      body: {
        plan,
        payment_method: paymentMethod.id,
      },
    });

    // The subscription contains an invoice
    // If the invoice's payment succeeded then you're good,
    // otherwise, the payment intent must be confirmed

    const { latest_invoice } = subscription;

    if (latest_invoice.payment_intent) {
      const { client_secret, status } = latest_invoice.payment_intent;

      if (status === "requires_action") {
        const { error: confirmationError } = await stripe.confirmCardPayment(
          client_secret
        );
        if (confirmationError) {
          console.error(confirmationError);
          alert("unable to confirm card");
          return;
        }
      }
    }

    updatePlanDetails();

    // success
    alert("You are subscribed!");
    getSubscriptions();

    setLoading(false);

    setPlan(null);
  };

  // Cancel a subscription
  const cancel = async (id) => {
    setLoading(true);
    if (window.confirm("Are you sure you wish to cancel your account?")) {
      await fetchFromAPI("subscriptions/" + id, { method: "PATCH" });
      db.collection("users").doc(user.uid).update({
        billed: "No",
      });
      alert("canceled!");
      await getSubscriptions();
    }
    setLoading(false);
  };

  return (
    <>
      <AuthCheck fallback={<SignIn />}>
        <section
          className="page-section bg-dark footer pb-60"
          style={{
            backgroundImage: "url(images/full-width-images/section-bg-1.jpg)",
          }}
        >
          <div className="container">
            <div
              className="local-scroll mb-30 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <h1 className="hs-line-11 font-alt mb-20 mb-xs-0">
                Manage Subsubscriptions
              </h1>
              <div className="hs-line-4 font-alt"></div>
            </div>
            <div
              className="local-scroll mb-30 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <div className="mod-breadcrumbs font-alt white align-center">
                <a href="/profile" className="btn btn-mod btn-w btn-circle">
                  <i className="fa   fa-user" aria-hidden="true"></i>
                  Back To Profile
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section" id="community">
          <div className="container relative">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-2 align-center">
                {(() => {
                  //Check to see what referral code has been used, if it is incorrect allow them to update
                  if (data && data.billed === "Yes") {
                    return (
                      <>
                        <h3 className="font-alt mb-70 mb-sm-40">
                          Welcome our community
                        </h3>

                        {subscriptions &&
                          subscriptions.map((sub) => (
                            <div key={sub.id}>
                              <h3 className="font-alt mb-70 mb-sm-40 text-danger">
                                Next payment of ${sub.plan.amount / 100} due{" "}
                                {new Date(
                                  sub.current_period_end * 1000
                                ).toUTCString()}{" "}
                              </h3>
                              <h4 className="font-alt mb-70 mb-sm-40">
                                To cancel your subscription press cancel{" "}
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => cancel(sub.id)}
                                  disabled={loading}
                                >
                                  Cancel
                                </button>
                              </h4>
                            </div>
                          ))}
                      </>
                    );
                  } else {
                    return (
                      <>
                        <h3 className="font-alt mb-70 mb-sm-40">
                          Join our community
                        </h3>

                        <div className="section-text mb-80 mb-xs-40">
                          <h3 className="font-alt mb-70 mb-sm-40">
                            <b>Step 1:</b> Please ensure you have updated your
                            discord username to do this please go{" "}
                            <Link to="/editprofile">here</Link>
                          </h3>
                          <h3 className="font-alt mb-70 mb-sm-40">
                            <b>Step 2:</b> Choose a Plan monthly or yearly
                          </h3>
                          <button
                            className={
                              "submit_btn btn btn-mod btn-medium btn-round " +
                              (plan === "price_1LX2cJEuudGs5blLSb4edkZ4"
                                ? "btn-primary"
                                : "btn-outline-primary")
                            }
                            onClick={planDetailsMonthly}
                          >
                            $67/m
                          </button>
                          &nbsp; | &nbsp;
                          <button
                            className={
                              "submit_btn btn btn-mod btn-medium btn-round" +
                              (plan === "price_1LX2d8EuudGs5blLPfmmIK09"
                                ? "btn-primary"
                                : "btn-outline-primary")
                            }
                            onClick={planDetailsYearly}
                          >
                            {/* <div className="salebanner">
                              <div className="ribbon-wrapper">
                                <div className="ribbon">SALE</div>
                              </div>
                            </div> */}
                            $497/y Save 38%
                          </button>
                          {/* &nbsp; | &nbsp;
                          <button
                            className={
                              "submit_btn btn btn-mod btn-medium btn-round" +
                              (plan === "price_1NxEO9EuudGs5blLqofXZ3kN"
                                ? "btn-primary"
                                : "btn-outline-primary")
                            }
                            onClick={planDetailsOneTime}
                          >
                            $97/OneTime Promo
                          </button> */}
                          <form
                            onSubmit={handleSubmit}
                            className="font-alt mb-70 mb-sm-40"
                            hidden={!plan}
                          >
                            <h3>
                              You have chosen the{" "}
                              <u>
                                <b>{planName}</b>
                              </u>{" "}
                              plan
                            </h3>
                            <h3>
                              <b>Step 3:</b> Enter your card info
                            </h3>

                            <hr />

                            <CardElement />
                            <button
                              className="submit_btn btn btn-mod btn-medium btn-round"
                              type="submit"
                              disabled={loading}
                            >
                              Subscribe & Pay
                            </button>
                          </form>
                        </div>
                      </>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </AuthCheck>
    </>
  );
}

export default function Subscriptions() {
  return (
    <Suspense fallback={"loading user"}>
      <SubscribeToPlan />
    </Suspense>
  );
}

import React, { useState, useRef } from "react";
//import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { auth, db } from "../firebase";

async function signup(email, password, firstName, lastName, referralCode) {
  await auth.createUserWithEmailAndPassword(email, password).then((cred) => {
    return db
      .collection("users")
      .doc(cred.user.uid)
      .set({
        fname: firstName.toLowerCase(),
        lname: lastName.toLowerCase(),
        email: email.toLowerCase(),
        userType: "User",
        referalCode: "thefxwave.com#" + email.toLowerCase(),
        signupDate: new Date(),
        billed: "No",
        totalRefered: 0,
        referalCodeUsed: referralCode.toLowerCase(),
        referalChecks: 0,
        avaibleWidthdrawl: 0,
      });
  });
}

export default function SignUp() {
  //Copy URL
  if (window.$name) {
    var modifiedURL = window.$name;
  }

  const fnameRef = useRef();
  const lnameRef = useRef();
  const emailRef = useRef();

  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const referralCodeRef = useRef();
  //const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handelSubmit(e) {
    e.preventDefault();

    /*if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }*/

    try {
      setError("");
      setLoading(true);
      await signup(
        emailRef.current.value,
        passwordRef.current.value,
        fnameRef.current.value,
        lnameRef.current.value,

        referralCodeRef.current.value
      );
      //history.push("/Dashboard");
    } catch {
      setError("Failed to create an account");
    }

    setLoading(false);
  }

  return (
    <>
      <form
        className="form contact-form"
        id="signup-form"
        onSubmit={handelSubmit}
      >
        <div className="clearfix">
          <div className="form-group" id="firstName">
            <input
              type="text"
              className="input-md round form-control"
              placeholder="First Name"
              ref={fnameRef}
              required
            />
          </div>

          <div className="form-group" id="lastName">
            <input
              type="text"
              ref={lnameRef}
              required
              className="input-md round form-control"
              placeholder="Last Name"
            />
          </div>

          <div className="form-group" id="email">
            <input
              type="email"
              className="input-md round form-control"
              placeholder="Email"
              ref={emailRef}
              required
            />
          </div>

          <div className="form-group" id="password">
            <input
              type="password"
              className="input-md round form-control"
              placeholder="Password"
              ref={passwordRef}
              required
            />
          </div>

          {(() => {
            if (modifiedURL) {
              return (
                <div className="form-group" id="referralCode">
                  <input
                    type="text"
                    value={"fxswaymarket.com#" + modifiedURL}
                    className="input-md round form-control"
                    placeholder="Enter a referral Code - optional"
                    ref={referralCodeRef}
                  />
                </div>
              );
            } else {
              return (
                <div className="form-group" id="referralCode">
                  <input
                    type="text"
                    ref={referralCodeRef}
                    className="input-md round form-control"
                    placeholder="Enter a referral Code - optional"
                  />
                </div>
              );
            }
          })()}
        </div>

        <div className="pt-10">
          <button
            className="submit_btn btn btn-mod btn-medium btn-round btn-full"
            id="reg-btn"
          >
            Register
          </button>
          <p className="error text-danger center-align"></p>
        </div>
      </form>

      {error !== null && (
        <div className="py-4 bg-red-600 w-full text-danger text-center mb-3">
          {error}
        </div>
      )}

      <div class="dark">
        <p class="disclaimerblock">
          Risk Disclaimer: Trading forex carries risk. Before participating in
          the FX market, you should carefully consider your investment
          objectives, and risk appetite. Past performance does not guarantee
          future results. You are investing at your own risk, you assume full
          responsibility. All information we provide is for educational
          purposes.
        </p>
      </div>
    </>
  );
}

import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useUser, AuthCheck } from "reactfire";

function BasicUserPage(props) {
  const user = useUser();

  //=========================== Videos //===========================
  const [videoTitle, setVideoTitle] = useState("");
  const [videoUrl, setVideoURL] = useState("");
  const [showVideo, setVideoShow] = useState("none");
  const [videoPostion, setVideoPostion] = useState("");
  const [videoTop, setVideoTop] = useState("");
  const [videoWidth, setVideoWidth] = useState("");
  const [videoMaxWidth, setvideoMaxWidth] = useState("");
  const [showCloseBtn, setShowCloseBtn] = useState("none");

  var myVideo = document.getElementsByTagName("video")[0];

  const changeVideo = (name, url) => {
    setVideoTitle(name);
    setVideoURL(url);
    setVideoShow("block");

    if (myVideo) {
      myVideo.load();
      myVideo.play();
    }
  };

  const closeVideo = () => {
    myVideo.pause();
    setVideoShow("none");
    setShowCloseBtn("none");
  };

  window.onscroll = () => {
    if (window.pageYOffset > 2174) {
      if (myVideo) {
        setVideoPostion("fixed");
        setVideoTop("10px");
        setVideoWidth("100%");
        setvideoMaxWidth("800px");
        setShowCloseBtn("block");
      }
    }

    if (window.pageYOffset < 1654) {
      if (myVideo) {
        setVideoPostion("");
        setVideoTop("");
        setVideoWidth("");
        setvideoMaxWidth("");
        setShowCloseBtn("none");
      }
    }
  };

  return (
    <>
      <section className="page-section" id="community">
        <div className="container relative">
          <div className="row">
            <div className="videoPlayerGrid">
              <div
                className="videoPlayer"
                style={{
                  display: showVideo,
                  position: videoPostion,
                  top: videoTop,
                  width: videoWidth,
                  maxWidth: videoMaxWidth,
                }}
              >
                <button
                  className="closeButton"
                  style={{ display: showCloseBtn }}
                  onClick={closeVideo}
                >
                  X
                </button>
                <h3 className="videoPlayerTitle alt-features-title font-alt">
                  Playing {videoTitle && videoTitle}
                </h3>

                <video
                  id="videoPlaying"
                  width="100%"
                  controls
                  controlsList="nodownload"
                >
                  <source src={videoUrl && videoUrl} type="video/mp4" />
                </video>
              </div>
              <div className="subGrid">
                <div
                  onClick={() =>
                    changeVideo(
                      "Getting Started",
                      "https://thefxwave.com/media/Getting%20Started.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">Start here</h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "Getting Started",
                      "https://thefxwave.com/media/Forex%20101.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">Forex 101</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default function Basic() {
  return (
    <Suspense
      fallback={
        <div class="page-loader">
          <div class="loader">Loading...</div>
        </div>
      }
    >
      <BasicUserPage />
    </Suspense>
  );
}

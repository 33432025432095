import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { FirebaseAppProvider } from "reactfire";

export const firebaseConfig = {
  apiKey: "AIzaSyDRSpVRtOoOQFmugj6zQT4h1pm__9RYCIg",
  authDomain: "project-stride-5806c.firebaseapp.com",
  projectId: "project-stride-5806c",
  storageBucket: "project-stride-5806c.appspot.com",
  messagingSenderId: "34377798394",
  appId: "1:34377798394:web:3b1db5b4ebd6e0326cef43",
};

export const stripePromise = loadStripe(
  "pk_live_51HwYBLEuudGs5blL3EJMwtmlkRUB3w5fl0RmkBhDA3OBKPOjVO45wjoICtjxPPBWs9TxZOdrHPFrd5e3fcihXSjk00iMqg1g6T"
  // "pk_test_51HwYBLEuudGs5blLwjq7p4wiRFM8dupc3wcQVW1tNK7AltBJC5SuDJEDiIzFH5hAkL3YxqlAzowSTvnXl1BUFtBk00HkqbRWkq"
);

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

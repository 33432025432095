import React, { useState, useEffect, Suspense, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useUser, AuthCheck } from "reactfire";

import { db } from "./firebase";
import { SignIn, SignOut } from "./Login";

function PremiumData(props) {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  // Subscribe to the user's data in Firestore
  useEffect(() => {
    const unsubscribe = db
      .collection("users")
      .doc(props.user.uid)
      .onSnapshot((doc) => setData(doc.data()));
    return () => unsubscribe();
  }, [props.user]);

  if (data) {
    //Referal Relationship
    //If billed is equal to yes display links to the user as a plan has been purchased
    if (data.billed === "Yes") {
      //console.log("Billing is " + data.billed);
      //Now that a plan has been brought check to see if there is a referal code used is set
      if (data.referalCodeUsed) {
        //Referal Code is equal to yes but not equal to NA so that we dont create another document
        if (data.referalCodeUsed !== "NA") {
          //console.log("A referal code has been used " + data.referalCodeUsed);

          //If there is a referal code we need to check to see if the referal code check has been done
          if (data.referalChecks < 1) {
            /*console.log(
            "Referal checks are less than 1 value = " + data.referalChecks
          );*/

            //Build the relationship
            db.collection("refererRelationship")
              .doc(props.user.uid)
              .set({
                //Master referer = the referal code used
                masterReferer: data.referalCodeUsed,
                //Child email is the active user who used the referal code
                childEmail: data.email,
                //Set activeBilling to yes
                activeBilling: "Yes",
                //Set creation Date
                creationDate: new Date(),
              })
              .then(() => {
                //Query the users collection where docs equal to the referal code this will show the master HE ONE WHO REFERED
                db.collection("users")
                  .where("referalCode", "==", data.referalCodeUsed)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      //Successfully found doc
                      var updateTotalCount = db.collection("users").doc(doc.id);

                      //Now update the field totalRefered and successfulRefers on the master with 1 everytime successfully billed
                      //totalRefered = the total amount of users who have used the code
                      //successfulRefers = the total successful people paying using your code
                      return updateTotalCount
                        .update({
                          totalRefered: doc.data().totalRefered + 1,
                          avaibleWidthdrawl: doc.data().avaibleWidthdrawl + 7,
                        })
                        .then(() => {
                          //Master referer document has been updated lastly update the referer checks document
                          db.collection("users").doc(props.user.uid).update({
                            referalChecks: 1,
                          });
                        });
                    });
                  })
                  .catch((error) => {
                    //Error getting the referal code for the master
                    //console.log("Error getting documents: ", error);
                  });
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          }
        }
      }
    }
  } else {
    console.log("Data not here");
  }

  //Get Link Data
  const [linkData, setLinkData] = useState({});

  const fetchLinks = async () => {
    const discordLinks = db
      .collection("links")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //console.log(doc.id, " => ", doc.data());
          setLinkData(doc.data());
        });
        return () => discordLinks();
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  useEffect(() => {
    fetchLinks();
  }, []);

  //=========================== Videos //===========================
  const [videoTitle, setVideoTitle] = useState("");
  const [videoUrl, setVideoURL] = useState("");
  const [showVideo, setVideoShow] = useState("none");
  const [videoPostion, setVideoPostion] = useState("");
  const [videoTop, setVideoTop] = useState("");
  const [videoWidth, setVideoWidth] = useState("");
  const [videoMaxWidth, setvideoMaxWidth] = useState("");
  const [showCloseBtn, setShowCloseBtn] = useState("none");

  var myVideo = document.getElementsByTagName("video")[0];

  const changeVideo = (name, url) => {
    setVideoTitle(name);
    setVideoURL(url);
    setVideoShow("block");

    if (myVideo) {
      myVideo.load();
      myVideo.play();
    }
  };

  const closeVideo = () => {
    myVideo.pause();
    setVideoShow("none");
    setShowCloseBtn("none");
  };

  window.onscroll = () => {
    if (window.pageYOffset > 2174) {
      if (myVideo) {
        setVideoPostion("fixed");
        setVideoTop("10px");
        setVideoWidth("100%");
        setvideoMaxWidth("800px");
        setShowCloseBtn("block");
      }
    }

    if (window.pageYOffset < 1654) {
      if (myVideo) {
        setVideoPostion("");
        setVideoTop("");
        setVideoWidth("");
        setvideoMaxWidth("");
        setShowCloseBtn("none");
      }
    }
  };

  return (
    <>
      <hr className="mt-0 mb-0" />

      <section className="page-section" id="community">
        <div className="container relative">
          <div className="row">
            <div className="videoPlayerGrid">
              <div
                className="videoPlayer"
                style={{
                  display: showVideo,
                  position: videoPostion,
                  top: videoTop,
                  width: videoWidth,
                  maxWidth: videoMaxWidth,
                }}
              >
                <button
                  className="closeButton"
                  style={{ display: showCloseBtn }}
                  onClick={closeVideo}
                >
                  X
                </button>
                <h3 className="videoPlayerTitle alt-features-title font-alt">
                  Playing {videoTitle && videoTitle}
                </h3>

                <video
                  id="videoPlaying"
                  width="100%"
                  controls
                  controlsList="nodownload"
                >
                  <source src={videoUrl && videoUrl} type="video/mp4" />
                </video>
              </div>
              <div className="videoTitles font-alt">
                Welcome to our community
              </div>
              <div className="subGrid">
                <div
                  onClick={() =>
                    changeVideo(
                      "Getting Started",
                      "https://thefxwave.com/media/Getting%20Started.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">Start here</h4>
                </div>
                <div
                  onClick={() =>
                    changeVideo(
                      "Intro to Forex",
                      "https://thefxwave.com/media/Forex%20101.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Intro to Forex
                  </h4>
                </div>
              </div>

              <div className="videoTitles font-alt">How To</div>

              <div className="subGrid">
                {/* <div
                  onClick={() =>
                    changeVideo(
                      "How To Download & Use Mt4 Desktop",
                      "https://thefxwave.com/media/How%20to%20Download%20MT4%20&%20Use.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    How To Download & Use Mt4 Desktop
                  </h4>
                </div> */}
                {/* <div
                  onClick={() =>
                    changeVideo(
                      "How To Download & Use Mt4 On Mobile",
                      "https://thefxwave.com/media/How%20to%20Download%20&%20Use%20MT4%20on%20Mobile.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    How To Download & Use Mt4 On Mobile
                  </h4>
                </div> */}

                <div
                  onClick={() =>
                    changeVideo(
                      "How To Take The Signals",
                      "https://thefxwave.com/media/How%20To%20Take%20The%20Signals.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    How To Take The Signals
                  </h4>
                </div>
                <div
                  onClick={() =>
                    changeVideo(
                      "How To Use Trading View",
                      "https://thefxwave.com/media/How%20to%20use%20trading%20view.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    How To Use Trading View
                  </h4>
                </div>
                <div
                  onClick={() =>
                    changeVideo(
                      "How to use Sway Charts Pro",
                      "https://fxwave.s3.amazonaws.com/media/how+to+use+sway+charts+pro.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    How to use Sway Charts Pro
                  </h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "Sway Charts Pro Mobile",
                      "https://fxwave.s3.amazonaws.com/media/sway+charts+pro+mobile.MP4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Sway Charts Pro Mobile
                  </h4>
                </div>
              </div>

              <div className="videoTitles font-alt">Trader Pyschology</div>
              <div className="subGrid">
                <div
                  onClick={() =>
                    changeVideo(
                      "The Mindset Of A Trader",
                      "https://thefxwave.com/media/Minset-of-A-Trader.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    The Mindset Of A Trader
                  </h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "Risk Management",
                      "https://thefxwave.com/media/Risk%20management.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Risk Management
                  </h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "Position Size Calculator",
                      "https://thefxwave.com/media/Position%20Size%20Calculator.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Position Size Calculator
                  </h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "Creating A Trading Plan",
                      "https://thefxwave.com/media/CreatingATradingPlan.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Creating A Trading Plan
                  </h4>
                </div>
              </div>

              <div className="videoTitles font-alt">
                Price Action & Market structure
              </div>
              <div className="subGrid">
                <div
                  onClick={() =>
                    changeVideo(
                      "Intro To Price Action & Market Structure",
                      "https://thefxwave.com/media/Intro%20to%20Price%20Action%20&%20Market%20Structure.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Intro To Price Action & Market Structure
                  </h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "Top Down Analysis",
                      "https://thefxwave.com/media/Top%20Down%20Analysis.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Top Down Analysis
                  </h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "Candlestick Analysis",
                      "https://thefxwave.com/media/Candlestick%20Analysis.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Candlestick Analysis
                  </h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "Identifying The Trend",
                      "https://thefxwave.com/media/Indentifying%20the%20trend.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Identifying The Trend
                  </h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "Marking Up Structure Levels",
                      "https://thefxwave.com/media/Marking%20up%20structure%20levels.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Marking Up Structure Levels
                  </h4>
                </div>
              </div>

              <div className="videoTitles font-alt">Supply & Demand</div>
              <div className="subGrid">
                <div
                  onClick={() =>
                    changeVideo(
                      "Supply Trading",
                      "https://thefxwave.com/media/Supply%20Zone%20Trading.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Supply Trading
                  </h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "Demand Trading",
                      "https://thefxwave.com/media/Demand%20Trading.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Demand Trading
                  </h4>
                </div>
              </div>

              <div className="videoTitles font-alt">Trend Line Strategy</div>
              <div className="subGrid">
                <div
                  onClick={() =>
                    changeVideo(
                      "TREND LINES",
                      "https://thefxwave.com/media/Trend%20Lines.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">TREND LINES</h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "COUNTER TREND LINES",
                      "https://thefxwave.com/media/Counter%20Trendlines.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    COUNTER TREND LINES
                  </h4>
                </div>
              </div>

              <div className="videoTitles font-alt">Major Market Patterns</div>
              <div className="subGrid">
                <div
                  onClick={() =>
                    changeVideo(
                      "DOUBLE TOPS & DOUBLE BOTTOMS",
                      "https://thefxwave.com/media/Double%20Tops%20&%20Double%20Bottoms.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    DOUBLE TOPS & DOUBLE BOTTOMS
                  </h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "HEAD & SHOULDERS",
                      "https://thefxwave.com/media/Head%20&%20Shoulders.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    HEAD & SHOULDERS
                  </h4>
                </div>
              </div>
              <div className="videoTitles font-alt">Correlations</div>
              <div className="subGrid">
                <div
                  onClick={() =>
                    changeVideo(
                      "DXY & JPY Correlations",
                      "https://thefxwave.com/media/DXY&JPYCorrelations.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    DXY & JPY Correlations
                  </h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "CAD & OIL Correlation",
                      "https://thefxwave.com/media/CAD&OILCorrelation.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    CAD & OIL Correlation
                  </h4>
                </div>

                <div
                  onClick={() =>
                    changeVideo(
                      "Indices & AUD correlation",
                      "https://thefxwave.com/media/Indices&AUDcorrelation.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Indices & AUD correlation
                  </h4>
                </div>
              </div>
              <div className="videoTitles font-alt">Entries & Exits</div>
              <div className="subGrid">
                <div
                  onClick={() =>
                    changeVideo(
                      "4hr Break & Retest",
                      "https://thefxwave.com/media/4hrBreakandRetestStrategy.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    4hr Break & Retest
                  </h4>
                </div>
                <div
                  onClick={() =>
                    changeVideo(
                      "Demand Zone Entry & Exit Strategy",
                      "https://fxwave.s3.amazonaws.com/media/Demand+Zone+Entry+%26+Exit+Strategy.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Demand Zone Entry & Exit Strategy
                  </h4>
                </div>
                <div
                  onClick={() =>
                    changeVideo(
                      "Supply Zone Entry & Exit Strategy",
                      "https://fxwave.s3.amazonaws.com/media/Supply+Zone+Entry+%26+Exit+Strategy.mp4"
                    )
                  }
                >
                  <img src="images/logos/wave-logo-vids.png" alt="" />
                  <h4 className="alt-features-title font-alt">
                    Supply Zone Entry & Exit Strategy
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function PremiumPage(props) {
  const user = useUser();
  return (
    <>
      <AuthCheck fallback={<SignIn />}>
        {user?.uid && <PremiumData user={user} />}
      </AuthCheck>
    </>
  );
}

export default function Premium() {
  return (
    <Suspense
      fallback={
        <div class="page-loader">
          <div class="loader">Loading...</div>
        </div>
      }
    >
      <PremiumPage />
    </Suspense>
  );
}

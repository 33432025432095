import React from "react";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

export default function AuthHome() {
  return (
    <main id="main">
      <div className="page" id="top">
        <section class="page-section bg-dark footer pb-60">
          <div class="container">
            <div
              class="local-scroll mb-30 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <h1 class="hs-line-11 font-alt mb-20 mb-xs-0">Join us</h1>
              <div class="hs-line-4 font-alt">
                In order to purchase please login or register
              </div>
            </div>
            <div class="mod-breadcrumbs font-alt white align-center">
              <a href="/" class="btn btn-mod btn-w btn-circle">
                <i class="fa  fa-home" aria-hidden="true"></i>
                Back To Home
              </a>
            </div>
          </div>
        </section>

        <section class="page-section">
          <div class="container relative">
            <div class="align-center mb-40 mb-xxs-30">
              <ul class="nav nav-tabs tpl-minimal-tabs">
                <li class="active">
                  <a href="#mini-one" data-toggle="tab">
                    Registration
                  </a>
                </li>

                <li>
                  <a href="#mini-two" data-toggle="tab">
                    Login
                  </a>
                </li>
              </ul>
            </div>
            <div class="tab-content tpl-minimal-tabs-cont section-text">
              <div class="tab-pane fade in active" id="mini-one">
                <div className="row">
                  <div className="col-md-4 col-md-offset-4">
                    <SignUp />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="mini-two">
                <div className="row">
                  <div className="col-md-4 col-md-offset-4">
                    <SignIn />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

import React, { useState, useEffect, Suspense, useRef } from "react";
import { AuthCheck } from "reactfire";
import { auth, db } from "../firebase";

import AuthHome from "./AuthHome";
import Footer from "./Footer";

function EditPage() {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user !== null) {
        db.collection("users")
          .doc(user.uid)
          .onSnapshot((doc) => setData(doc.data()));
      }
    });

    return unsubscribe;
  }, []);

  const [error, setError] = useState("");
  const fnameRef = useRef();
  const lnameRef = useRef();
  const discorduserRef = useRef();
  const [isDisabled, setIsDisabled] = useState(true);

  function onChange() {
    if (fnameRef.current.value) {
      setIsDisabled(false);
    }

    if (lnameRef.current.value) {
      setIsDisabled(false);
    }

    if (discorduserRef.current.value) {
      setIsDisabled(false);
    }
  }

  async function handelSubmit(e) {
    e.preventDefault();

    if (fnameRef.current.value === "") {
      fnameRef.current.value = data.fname;
    }

    if (lnameRef.current.value === "") {
      lnameRef.current.value = data.lname;
    }

    if (discorduserRef.current.value === "") {
      discorduserRef.current.value = data.discordUser;
    }

    var newLinkUpdate = db.collection("users").doc(currentUser.uid);

    // Set the "capital" field of the city 'DC'
    return newLinkUpdate
      .update({
        fname: fnameRef.current.value,
        lname: lnameRef.current.value,
        discordUser: discorduserRef.current.value,
      })
      .then(() => {
        setError("New profile updates have been processed");
        setIsDisabled(true);
      })
      .catch((error) => {
        // The document probably doesn't exist.
        setError("Error updating profile: ", error);
      });
  }

  return (
    <>
      {" "}
      <AuthCheck fallback={<AuthHome />}>
        <section
          className="page-section bg-dark footer pb-60"
          style={{
            backgroundImage: "url(images/full-width-images/section-bg-1.jpg)",
          }}
        >
          <div className="container">
            <div
              className="local-scroll mb-30 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <h1 className="hs-line-11 font-alt mb-20 mb-xs-0">
                Edit {data && data.fname}
              </h1>
              <div className="hs-line-4 font-alt"></div>
            </div>
            <div
              className="local-scroll mb-30 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <div class="mod-breadcrumbs font-alt white align-center">
                <a href="/profile" class="btn btn-mod btn-w btn-circle">
                  <i class="fa   fa-user" aria-hidden="true"></i>
                  Back To Profile
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section" id="details">
          <div className="container relative">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="work-detail">
                  <span>
                    <h5 className="font-alt mt-0 mb-20">
                      Edit Profile Details{" "}
                    </h5>
                  </span>
                  <div className="work-full-detail">
                    <form
                      className="form contact-form"
                      id="login-form"
                      onSubmit={handelSubmit}
                      onChange={onChange}
                    >
                      <p>
                        <strong>First Name:</strong>
                        <div>
                          <div className="form-group" id="firstname">
                            <input
                              type="text"
                              className="input-md round form-control"
                              ref={fnameRef}
                              placeholder={data && data.fname}
                            />
                          </div>
                        </div>
                      </p>
                      <p>
                        <strong>Last Name:</strong>
                        <div className="form-group" id="lastname">
                          <input
                            type="text"
                            className="input-md round form-control"
                            ref={lnameRef}
                            placeholder={data && data.lname}
                          />
                        </div>
                      </p>

                      <p>
                        <strong>Discord Username:</strong>

                        <div className="form-group" id="discorduser">
                          <input
                            type="text"
                            className="input-md round form-control"
                            ref={discorduserRef}
                            placeholder={data && data.discordUser}
                          />
                        </div>
                      </p>
                      <div className="clearfix">
                        <div className="cf-left-col">
                          <div className="form-tip pt-20"></div>
                        </div>

                        <div className="pt-10">
                          {!isDisabled && (
                            <button
                              className="submit_btn btn btn-mod btn-medium btn-round btn-full"
                              id="reg-btn"
                            >
                              Update Profile
                            </button>
                          )}
                          {isDisabled && (
                            <button
                              disabled
                              className="submit_btn btn btn-mod btn-medium btn-round btn-full"
                              id="reg-btn"
                            >
                              Enter new content to unlock
                            </button>
                          )}
                          <p className="error text-danger center-align"></p>
                        </div>
                        {error !== null && (
                          <div className="py-4 bg-red-600 w-full text-danger text-center mb-3">
                            {error}
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </AuthCheck>
    </>
  );
}

export default function EditProfile() {
  return (
    <Suspense
      fallback={
        <div className="page-loader">
          <div className="loader">Loading...</div>
        </div>
      }
    >
      <EditPage />
    </Suspense>
  );
}

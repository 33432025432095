import React from "react";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Subscriptions from "./Subscriptions";
import Profile from "./Profile";
import Forgot from "./Forgot";
import Admin from "./Admin";
import Premium from "./Premium";
import Basic from "./Basic";
import Home from "./components/Home";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import AuthHome from "./components/AuthHome";
import EditProfile from "./components/EditProfile";
import NewBlog from "./components/NewBlog";
import VideoPlayer from "./components/VideoPlayer";

function App() {
  //Copy URL
  var url = new URL(document.URL);
  var urlhash = url.hash;
  var userIDhash = urlhash.substring(1);
  var SplitIDHash = userIDhash.split("#");

  if (SplitIDHash[0]) {
    var referredURL = SplitIDHash[0];
    window.$name = referredURL;
    //console.log("URL = " + window.$name);
  }

  return (
    <Router>
      <Switch>
        <Route path="/VideoPlayer">
          <VideoPlayer />
        </Route>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="/basic">
          <Basic />
        </Route>
        <Route path="/premium">
          <Premium />
        </Route>
        <Route path="/AuthHome">
          <AuthHome />
        </Route>
        <Route path="/forgot">
          <Forgot />
        </Route>

        <Route exact path="/profile">
          <Profile />
        </Route>
        <Route exact path="/subscriptions">
          <Subscriptions />
        </Route>
        <Route exact path="/newblog">
          <NewBlog />
        </Route>
        <Route exact path="/editprofile">
          <EditProfile />
        </Route>

        <Route to path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

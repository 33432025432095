import React, { useState, useEffect, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { useUser, AuthCheck } from "reactfire";
import { fetchFromAPI } from "./helpers";

import { auth, db } from "./firebase";

import copy from "copy-to-clipboard";

import Basic from "./Basic";
import Premium from "./Premium";
import AuthHome from "./components/AuthHome";
import Footer from "./components/Footer";
import DiscordPreview from "./components/DiscordPopUp";

export function SignOut(props) {
  return (
    props.user && (
      <button
        className="btn btn-mod btn-w btn-circle btn-icon btn-small"
        onClick={() => auth.signOut()}
      >
        <span>
          <i class="fa fa-home"></i>
        </span>
        Sign Out
      </button>
    )
  );
}

function UserPage() {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user !== null) {
        db.collection("users")
          .doc(user.uid)
          .onSnapshot((doc) => setData(doc.data()));
      }
    });

    return unsubscribe;
  }, []);

  //Withdraw requests
  const [withdrawlAmount, setWithdrawlAmount] = useState("");

  const onWithdrawChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "withdraw-amount") {
      setWithdrawlAmount(value);
    }
  };

  const withdrawReq = async (event, withdrawlAmount) => {
    event.preventDefault();
    console.log(withdrawlAmount);
    if (withdrawlAmount <= 0) {
      setError("You cannot withdraw $0");
    } else {
      if (withdrawlAmount > data.avaibleWidthdrawl) {
        setError("You cannot request more than availble to withdraw");
        console.error(
          "You cannot request more than availble to withdraw",
          error
        );
      } else {
        db.collection("withdrawalRequests")
          .add({
            user: data.email,
            amount: withdrawlAmount,
            status: "Pending",
            requestDate: new Date(),
            userID: currentUser.uid,
          })
          .then(() => {
            setWithdrawlAmount(0);
            setSuccess(
              "Your request for withdrawl has been sent please allow from 48 hours to 7 days for this to be processed"
            );
          })
          .catch((err) => {
            console.log(err.message);
          });

        //Change withdrawal data
        var changeAccountState = db.collection("users").doc(currentUser.uid);

        return changeAccountState
          .update({
            avaibleWidthdrawl: data.avaibleWidthdrawl - withdrawlAmount,
          })
          .then(() => {
            //Successfully updated the document
            //console.log("Document successfully updated!");
          })
          .catch((error) => {
            //The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      }
    }
  };

  //Copy function
  const handleCopy = () => {
    copy(data.referalCode);
  };

  //Get Link Data
  const [linkData, setLinkData] = useState({});

  const fetchLinks = async () => {
    const discordLinks = db
      .collection("links")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setLinkData(doc.data());
        });
        return () => discordLinks();
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  useEffect(() => {
    fetchLinks();
  }, []);

  return (
    <>
      <AuthCheck fallback={<AuthHome />}>
        <section
          className="page-section bg-dark footer pb-60"
          style={{
            backgroundImage: "url(images/full-width-images/section-bg-1.jpg)",
          }}
        >
          <div className="container">
            <div
              className="local-scroll mb-30 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <h1 className="hs-line-11 font-alt mb-20 mb-xs-0">
                Welcome {data && data.fname}
              </h1>
              <div className="hs-line-4 font-alt">
                Products and services are below
              </div>
            </div>
            <div
              className="local-scroll mb-30 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <div class="mod-breadcrumbs font-alt white align-center">
                <a href="/" class="btn btn-mod btn-w btn-circle">
                  <i class="fa  fa-home" aria-hidden="true"></i>
                  Back To Home
                </a>
                &nbsp;&nbsp;
                <a
                  href="#"
                  onClick={() => auth.signOut()}
                  class="btn btn-mod btn-w btn-circle"
                >
                  <i class="fa fa-sign-out" aria-hidden="true"></i>
                  Sign out
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section" id="details">
          <div className="container relative">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="work-detail">
                  <span>
                    <h5 className="font-alt mt-0 mb-20">Profile Details </h5>
                  </span>
                  <div className="work-full-detail">
                    <p>
                      <strong>Edit Profile:</strong>
                      <span>
                        <Link to="/editprofile">
                          <button type="button">
                            <i className="fa fa-pencil-square-o"></i>
                          </button>
                        </Link>
                      </span>
                    </p>
                    <p>
                      <strong>First Name:</strong>
                      <div>{data && data.fname}</div>
                    </p>
                    <p>
                      <strong>Last Name:</strong>
                      <div>{data && data.lname}</div>
                    </p>
                    <p>
                      <strong>Email:</strong>
                      <div>{data && data.email}</div>
                    </p>
                    <p>
                      <strong>Referral Code:</strong>
                      <div>
                        {data && data.referalCode}{" "}
                        <span>
                          <button onClick={handleCopy}>
                            <i className="fa fa-copy "></i>
                          </button>
                        </span>
                      </div>
                    </p>
                    <p>
                      <strong>Users referred:</strong>
                      <div>
                        {(() => {
                          //Check to see what referral code has been used, if it is incorrect allow them to update
                          if (data && data.totalRefered === 0) {
                            return <>{"0 users have been referred"}</>;
                          } else {
                            return <>{data && data.totalRefered}</>;
                          }
                        })()}
                      </div>
                    </p>
                    <p>
                      <strong>Referral Code used:</strong>

                      <div>{data && data.referalCodeUsed}</div>
                    </p>
                    <p>
                      <strong>Available to withdraw:</strong>
                      <div>${data && data.avaibleWidthdrawl}</div>
                    </p>

                    {(() => {
                      //Check to see what referral code has been used, if it is incorrect allow them to update
                      if (data && data.discordUser) {
                        return (
                          <p>
                            <strong>Discord Username:</strong>
                            <div>{data && data.discordUser}</div>
                          </p>
                        );
                      } else {
                        return (
                          <p>
                            <strong>Discord Username:</strong>
                            <div className="py-4 bg-red-600 w-full text-danger">
                              Please update your discord user name via edit
                              profile
                            </div>
                          </p>
                        );
                      }
                    })()}

                    <div>
                      {(() => {
                        //Check to see what referral code has been used, if it is incorrect allow them to update
                        if (data && data.currentSubPeriod) {
                          return (
                            <p>
                              <strong>Next Bill Date:</strong>
                              <div>{data && data.currentSubPeriod}</div>
                            </p>
                          );
                        }
                      })()}
                    </div>
                  </div>
                </div>

                <br />

                {(() => {
                  if (data && data.billed === "Yes") {
                    return (
                      <>
                        <div>
                          <h4 className="alt-features-title font-alt">
                            Manage your account
                          </h4>
                          <a
                            href={linkData && linkData.Content}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button type="button" className="btn btn-purple ">
                              <i
                                className="fa fa-comment"
                                aria-hidden="true"
                              ></i>
                              <span>&nbsp; Enter Discord</span>
                            </button>
                          </a>

                          <span>&nbsp;</span>

                          <Link to="/subscriptions">
                            <button type="button" className="btn btn-warning">
                              <i
                                className="fa fa-dollar"
                                aria-hidden="true"
                              ></i>
                              <span>&nbsp; Enter Subscription</span>
                            </button>
                          </Link>
                        </div>
                        <br />
                        <div>
                          <h4 className="alt-features-title font-alt">
                            Join our brokerage of choice{" "}
                            <a
                              href="https://my.swaymarkets.com/auth/register?partner_code=9218974"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              SwayMarkets
                            </a>
                          </h4>

                          <h4 className="alt-features-title font-alt">
                            Learn how to use our broker of choice{" "}
                            <a
                              href="https://support.swaymarkets.com/hc/en-us"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              SwayMarkets
                            </a>
                          </h4>
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <h4 className="alt-features-title font-alt">
                          Manage your account
                        </h4>
                        <Link to="/subscriptions">
                          <button type="button" className="btn btn-warning">
                            <i className="fa fa-dollar" aria-hidden="true"></i>
                            <span>
                              &nbsp; Click to subscribe to our community for
                              only $67 per month
                            </span>
                          </button>
                        </Link>

                        <div>
                          <h4 className="alt-features-title font-alt">
                            Join our brokerage of choice{" "}
                            <a
                              href="https://my.swaymarkets.com/auth/register?partner_code=9218974"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              SwayMarkets
                            </a>
                          </h4>

                          <h4 className="alt-features-title font-alt">
                            Learn how to use our broker of choice{" "}
                            <a
                              href="https://support.swaymarkets.com/hc/en-us"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              SwayMarkets
                            </a>
                          </h4>
                        </div>
                      </>
                    );
                  }
                })()}

                <br />
                <br />

                {(() => {
                  if (data && data.userType === "Admin") {
                    return (
                      <>
                        <Link to="/admin">
                          <button type="button" className="btn btn-danger">
                            <i className="fa fa-gear" aria-hidden="true"></i>
                            <span>&nbsp; Admin Panel</span>
                          </button>
                        </Link>
                      </>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </section>

        <hr className="mt-0 mb-0" />

        {(() => {
          if (data && !data.discordUser && data.billed === "Yes")
            return <DiscordPreview />;
        })()}

        <section className="page-section" id="withdraw">
          <div className="container relative">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <h4>
                  Total funds available to withdraw $
                  {data && data.avaibleWidthdrawl}
                </h4>

                <form className="form contact-form" id="withdraw-form">
                  <div className="clearfix">
                    <div className="form-group">
                      <input
                        type="number"
                        name="withdraw-amount"
                        id="withdraw-amount"
                        className="input-md round form-control"
                        placeholder="Enter a withdrawal amount"
                        required
                        aria-required="true"
                        value={withdrawlAmount}
                        onChange={(event) => onWithdrawChangeHandler(event)}
                      />
                    </div>
                  </div>

                  <div className="clearfix">
                    <div className="cf-right-col">
                      <div className="align-right pt-10">
                        <button
                          className="submit_btn btn btn-mod btn-medium btn-round"
                          id="request-withdraw-btn"
                          onClick={(event) =>
                            withdrawReq(event, withdrawlAmount)
                          }
                        >
                          Request
                        </button>
                      </div>

                      {error !== null && (
                        <div className="py-4 bg-red-600 w-full text-danger text-center mb-3">
                          {error}
                        </div>
                      )}

                      {success !== null && (
                        <div className="py-4 bg-red-600 w-full text-siccess text-center mb-3">
                          {success}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <hr className="mt-0 mb-0" />
        {(() => {
          if (data && data.billed === "Yes") {
            return <Premium />;
          } else {
            return <Basic />;
          }
        })()}

        <Footer />
      </AuthCheck>
    </>
  );
}

export default function Profile() {
  return (
    <Suspense
      fallback={
        <div className="page-loader">
          <div className="loader">Loading...</div>
        </div>
      }
    >
      <UserPage />
    </Suspense>
  );
}

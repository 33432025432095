import React from "react";
import { BrowserRouter as Link } from "react-router-dom";
import Footer from "./Footer";
import NavBar from "./NavBar";

import "./Home.scss";

export default function Home() {
  return (
    <>
      <NavBar />
      <main id="main">
        <section
          className="home-section bg-dark-alfa-30 parallax-2"
          style={{
            backgroundImage: "url(images/full-width-images/section-bg-1.jpg)",
          }}
          id="home"
        >
          <div className="js-height-full container">
            <div className="home-content">
              <div className="home-text">
                <h1 className="hs-line-1 font-alt mb-80 mb-xs-30 mt-70 mt-sm-0">
                  FX Wave
                </h1>

                <p className="hs-line-6">Welcome to our trading community</p>
              </div>
            </div>

            <div className="local-scroll">
              <a href="#about" className="scroll-down">
                <i className="fa fa-angle-down scroll-down-icon"></i>
                <span className="sr-only">Scroll to the next section</span>
              </a>
            </div>
          </div>
        </section>
        <section className="page-section" id="about">
          <div className="container relative">
            <h2 className="section-title font-alt align-left mb-70 mb-sm-40">
              About FX Wave
            </h2>

            <div className="section-text mb-50 mb-sm-20">
              <div className="row">
                <div className="col-md-4">
                  <blockquote>
                    <p>The market has a rhythm, tap into it.</p>
                    <footer>FX Wave</footer>
                  </blockquote>
                </div>

                <div className="col-md-4 col-sm-6 mb-sm-50 mb-xs-30">
                  Here you will find educational services; from learning the
                  basics of trading, to developing a professional mindset to
                  master your trading craft.
                </div>

                <div className="col-md-4 col-sm-6 mb-sm-50 mb-xs-30">
                  Branden Blackwill, a heavily recruited and sought after
                  financial analyst has partnered with FX Wave to create this
                  market platform to teach and create a trading community for
                  all levels of traders.
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 mb-xs-30 wow fadeInUp">
                <div className="team-item">
                  <div className="team-item-image">
                    <img
                      src="images/logos/photo_2021-02-16 23.52.12.jpeg"
                      alt=""
                    />

                    <div className="team-item-detail">
                      <h4 className="font-alt normal">Lewis Neal</h4>

                      <p></p>

                      <div className="team-social-links">
                        <a
                          href="https://instagram.com/iamlewisneal?igshid=1jafw79n6wlym"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-instagram"></i>
                          <span className="sr-only">Instagram profile</span>
                        </a>
                        <a
                          href="www.facebook.com/LewisMNeal"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-facebook"></i>
                          <span className="sr-only">Facebook profile</span>
                        </a>
                        <a
                          href="https://twitter.com/iamlewisneal?s=11"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-twitter"></i>
                          <span className="sr-only">Twitter profile</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="team-item-descr font-alt">
                    <div className="team-item-name">Lewis Neal</div>

                    <div className="team-item-role">Advisor</div>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-6 mb-xs-30 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-item-image">
                    <img
                      src="images/logos/photo_2021-02-16 23.45.49.jpeg"
                      alt=""
                    />

                    <div className="team-item-detail">
                      <h4 className="font-alt normal">Branden Blackwill</h4>

                      <p></p>

                      <div className="team-social-links">
                        <a
                          href="https://www.instagram.com/brandenblackwill_/?igshid=3tftll7770rj"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-instagram"></i>
                          <span className="sr-only">Instagram profile</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="team-item-descr font-alt">
                    <div className="team-item-name">Branden Blackwill</div>

                    <div className="team-item-role">Financial Analyst</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section pt-0 pb-0 banner-section bg-dark">
          <div className="container">
            <div className="row mb-70 mb-xs-40">
              <div className="col-md-8 col-md-offset-2">
                <div className="section-text align-center">
                  <h2 className="section-title font-alt mb-70 mb-sm-40 white">
                    Join us today
                  </h2>
                </div>
              </div>
            </div>

            <div className="row multi-columns-row" id="pricing">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="pricing-item main">
                  <div className="pricing-item-inner">
                    <div className="pricing-wrap">
                      <div className="pricing-title">
                        Premium Monthly Service
                      </div>
                      <div className="pricing-features font-alt">
                        <ul className="sf-list pr-list white">
                          <li>
                            Forex & Commodity Trade Alerts with fixed take
                            profit and stop loss given
                          </li>
                          <li>2-5 trade alerts per week, 200 pips on avg</li>
                          <li>Live Trading Sessions going in depth with Q&A</li>
                          <li>
                            Daily chart analysis & reasoning for entering trades
                          </li>
                          <li>
                            Access To VIP Private Discord Community with other
                            traders & investors. Create a discord account for
                            signals
                          </li>
                          <li>
                            Learn our strategies watching pre recorded videos
                          </li>
                        </ul>
                      </div>

                      <div className="pricing-num">
                        <sup>$</sup>67
                      </div>

                      <div className="pr-per">per month</div>

                      <div className="pr-button">
                        <a href="/profile" className="btn btn-mod">
                          Sign up & Buy Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="pricing-item main">
                  <div className="pricing-item-inner">
                    <div className="pricing-wrap">
                      <div className="salebanner">
                        <div class="ribbon-wrapper">
                          <div class="ribbon">Save 38%</div>
                        </div>
                      </div>
                      <div className="pricing-title">
                        Premium Yearly Service
                      </div>
                      <div className="pricing-features font-alt">
                        <ul className="sf-list pr-list white">
                          <li>
                            Forex & Commodity Trade Alerts with fixed take
                            profit and stop loss given
                          </li>
                          <li>2-5 trade alerts per week, 200 pips on avg</li>
                          <li>Live Trading Sessions going in depth with Q&A</li>
                          <li>
                            Daily chart analysis & reasoning for entering trades
                          </li>
                          <li>
                            Access To VIP Private Discord Community with other
                            traders & investors. Create a discord account for
                            signals
                          </li>
                          <li>
                            Learn our strategies watching pre recorded videos
                          </li>
                        </ul>
                      </div>

                      <div className="pricing-num">
                        <sup>$</sup> 497
                      </div>

                      <div className="pr-per">per year</div>

                      <div className="pr-button">
                        <a href="/profile" className="btn btn-mod">
                          Sign up & Buy Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="pricing-item main">
                  <div className="pricing-item-inner">
                    <div className="pricing-wrap">
                      <div className="salebanner">
                        <div class="ribbon-wrapper">
                          <div class="ribbon">Promo</div>
                        </div>
                      </div>
                      <div className="pricing-title">
                        Premium Lifetime Service
                      </div>
                      <div className="pricing-features font-alt">
                        <ul className="sf-list pr-list white">
                          <li>
                            Forex & Commodity Trade Alerts with fixed take
                            profit and stop loss given
                          </li>
                          <li>2-5 trade alerts per week, 200 pips on avg</li>
                          <li>Live Trading Sessions going in depth with Q&A</li>
                          <li>
                            Daily chart analysis & reasoning for entering trades
                          </li>
                          <li>
                            Access To VIP Private Discord Community with other
                            traders & investors. Create a discord account for
                            signals
                          </li>
                          <li>
                            Learn our strategies watching pre recorded videos
                          </li>
                        </ul>
                      </div>

                      <div className="pricing-num">
                        <sup>$</sup> 97
                      </div>

                      <div className="pr-per">One Time</div>

                      <div className="pr-button">
                        <a href="/profile" className="btn btn-mod">
                          Sign up & Buy Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="page-section" id="services">
          <div className="container relative">
            <h2 className="section-title font-alt mb-70 mb-sm-40">Services</h2>

            <div className="tab-content tpl-tabs-cont">
              <div className="tab-pane fade in active" id="">
                <div className="section-text">
                  <div className="row">
                    <div className="col-sm-3 mb-40">
                      <ul className="nav nav-tabs tpl-alt-tabs font-alt pt-30 pt-sm-0 pb-30 pb-sm-0">
                        <li className="active">
                          <a href="#" data-toggle="tab">
                            <div className="alt-tabs-icon">
                              <span className="icon-linegraph"></span>
                            </div>
                            Trade Analysis
                          </a>
                        </li>
                      </ul>

                      <div className="text">
                        Leverage our real time trade alerts that’ll enable you
                        to copy the same trades we execute!
                      </div>
                    </div>

                    <div className="col-sm-3 mb-40">
                      <ul class="nav nav-tabs tpl-alt-tabs font-alt pt-30 pt-sm-0 pb-30 pb-sm-0">
                        <li class="active">
                          <a href="#" data-toggle="tab">
                            <div class="alt-tabs-icon">
                              <span class="icon-video"></span>
                            </div>
                            Live Sessions
                          </a>
                        </li>
                      </ul>
                      <div class="text">
                        Join our live trading & educational sessions, where you
                        can get a hands on trading experience & all of your
                        questions answered.
                      </div>
                    </div>

                    <div class="col-sm-3 mb-40">
                      <ul className="nav nav-tabs tpl-alt-tabs font-alt pt-30 pt-sm-0 pb-30 pb-sm-0">
                        <li className="active">
                          <a href="#" data-toggle="tab">
                            <div className="alt-tabs-icon">
                              <span className="icon-strategy"></span>
                            </div>
                            Technical Strategies
                          </a>
                        </li>
                      </ul>
                      <div className="text">
                        Learn simplified trading strategies. Educational content
                        will be uploaded in back office for members to have
                        unlimited accessibility to all material.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="page-section pt-0 pb-0 banner-section bg-dark"
          data-background="images/full-width-images/section-bg-2.jpg"
        >
          <div className="container relative">
            <div className="row">
              <div class="col-sm-6">
                <div class="mt-140 mt-lg-80 mb-140 mb-lg-80">
                  <div class="banner-content">
                    <h3 class="banner-heading font-alt">
                      Looking to get in contact with us?
                    </h3>
                    <div class="banner-decription">
                      Feel free to send us a message, we look forward to hearing
                      from you and assisting you the best way we can.
                    </div>
                    <div class="local-scroll">
                      <a
                        href="#contact"
                        class="btn btn-mod btn-w btn-medium btn-round"
                      >
                        Lets talk
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 banner-image wow fadeInUp">
                <img src="images/charts/pc-chart-1.png" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section class="page-section" id="whychooseus">
          <div class="container relative">
            <h2 class="section-title font-alt mb-70 mb-sm-40">
              Why Choose Us?
            </h2>

            <div class="row multi-columns-row alt-features-grid">
              <div class="col-sm-6 col-md-4 col-lg-4">
                <div class="alt-features-item align-center">
                  <div class="alt-features-icon">
                    <span class="icon-trophy"></span>
                  </div>
                  <h3 class="alt-features-title font-alt">Our Goal</h3>
                  <div class="alt-features-descr align-left">
                    Our goal is to not be like every other platform. We want our
                    trading community to have all the best tools and resources
                    to be on the winning side of the market.
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-4 col-lg-4">
                <div class="alt-features-item align-center">
                  <div class="alt-features-icon">
                    <span class="icon-linegraph"></span>
                  </div>
                  <h3 class="alt-features-title font-alt">The Experience</h3>
                  <div class="alt-features-descr align-left">
                    Our team is built around experienced and proven market
                    leaders, who are highly respected and trusted in the trading
                    community.
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-4 col-lg-4">
                <div class="alt-features-item align-center">
                  <div class="alt-features-icon">
                    <span class="icon-scope"></span>
                  </div>
                  <h3 class="alt-features-title font-alt">Personal</h3>
                  <div class="alt-features-descr align-left">
                    We will work personally with you to make sure you’re
                    adequately equipped to trade in the markets.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="page-section bg-dark bg-dark-alfa-90 fullwidth-slider"
          data-background="images/full-width-images/section-bg-3.jpg"
          id="testimonials"
        >
          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 1.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 2.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 3.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 4.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 5.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 6.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 7.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 8.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 9.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 10.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 11.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 12.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 13.jpeg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 14.jpg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="container relative">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 align-center">
                  <div class="section-icon">
                    <span class="icon-quote"></span>
                  </div>
                  <h3 class="small-title font-alt">What people say?</h3>
                  <blockquote class="testimonial white">
                    <img src="images/testimonies/test 15.jpg" />
                    <p></p>
                    <footer class="testimonial-author"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="page-section" id="news">
          <div class="container relative">
            <h2 class="section-title font-alt align-left mb-70 mb-sm-40">
              Latest News
              <a href="#" class="section-more right">
                All News in our blog <i class="fa fa-angle-right"></i>
              </a>
            </h2>

            <div class="row multi-columns-row">
              <div
                class="col-sm-6 col-md-4 col-lg-4 mb-md-50 wow fadeIn"
                data-wow-delay="0.1s"
                data-wow-duration="2s"
              >
                <div class="post-prev-img">
                  <a href="blog-single-sidebar-right.html">
                    <img
                      src="images/blog/photo_2021-02-17 14.49.12.jpeg"
                      alt=""
                    />
                  </a>
                </div>

                <div class="post-prev-title font-alt">
                  <a href="">Currency and Crypto Analysis</a>
                </div>

                <div class="post-prev-info font-alt">
                  <a href="">Branden Blackwill</a> | 2021
                </div>

                <div class="post-prev-text">
                  View our history on the outlook of our past trades.
                </div>

                <div class="post-prev-more">
                  <a href="" class="btn btn-mod btn-gray btn-round">
                    Read More <i class="fa fa-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

import React, { useState, useEffect, Suspense } from "react";

export default function VideoPlayer() {
  function VideoPlayerController(props) {
    return <h1>Video Player</h1>;
  }
  return (
    <Suspense
      fallback={
        <div class="page-loader">
          <div class="loader">Loading...</div>
        </div>
      }
    >
      <VideoPlayerController />
    </Suspense>
  );
}

import { auth } from "./firebase";
const API = "https://sway-fx-6pm73tzwva-uc.a.run.app";
//const API = "http://localhost:3333";

/**
 * A helper function to fetch data from your API.
 * It sets the Firebase auth token on the request.
 */
export async function fetchFromAPI(endpointURL, opts) {
  const { method, body } = { method: "POST", body: null, ...opts };

  const user = auth.currentUser;
  const token =
    user &&
    (await user.getIdToken()); /*.then(function (idToken) {
      console.log("TOKEN 2 = " + token);
      // Send token to your backend via HTTPS
      // ...
    })
    .catch(function (error) {
      // Handle error
    });*/ //true)
  //console.log("TOKEN 1 = " + token);
  /*user
    .getIdToken(/* forceRefresh */ const res = await fetch(
    `${API}/${endpointURL}`,
    {
      method,
      ...(body && { body: JSON.stringify(body) }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.json();
}

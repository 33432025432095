import React, { useState, useEffect, Suspense, useRef } from "react";
import { useUser, AuthCheck } from "reactfire";
import AuthHome from "./AuthHome";
import { db } from "../firebase";

function NewBlogPage(props) {
  const blogTitleRef = useRef();
  const blogBodyRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const user = useUser();
  useEffect(() => {
    const unsubscribe = db
      .collection("users")
      .doc(user.uid)
      .onSnapshot((doc) => setData(doc.data()));
    return () => unsubscribe();
  }, [props.user]);

  function handelSubmit(e) {
    e.preventDefault();
    console.log("Creating new blog");
    try {
      setError("");
      setLoading(true);
      db.collection("blogs")
        .doc("LA")
        .set({
          title: blogTitleRef,
          body: blogBodyRef,
          signupDate: new Date(),
          author: data.fname + " " + data.lname,
        });
      //history.push("/Dashboard");
    } catch {
      setError(e.err);
      console.log(e);
    }

    setLoading(false);
  }

  return (
    <>
      <AuthCheck fallback={<AuthHome />}>
        <section
          className="page-section bg-dark footer pb-60"
          style={{
            backgroundImage: "url(images/full-width-images/section-bg-1.jpg)",
          }}
        >
          <div className="container">
            <div
              className="local-scroll mb-30 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <h1 className="hs-line-11 font-alt mb-20 mb-xs-0">
                Create a new Blog
              </h1>
              <div className="hs-line-4 font-alt"></div>
            </div>
            <div
              className="local-scroll mb-30 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <div className="mod-breadcrumbs font-alt white align-center">
                <a href="/profile" className="btn btn-mod btn-w btn-circle">
                  <i className="fa   fa-user" aria-hidden="true"></i>
                  Back To Profile
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section" id="withdraw">
          <div className="container relative">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-2 align-center">
                <h4 className="font-alt mb-70 mb-sm-40">Enter todays link</h4>

                <form
                  className="form contact-form"
                  id="withdraw-form"
                  onSubmit={handelSubmit}
                >
                  <div className="clearfix">
                    <div className="form-group">
                      <input
                        type="text"
                        name="new-link"
                        ref={blogTitleRef}
                        className="input-md round form-control"
                        placeholder="Enter a blog title"
                        required
                        aria-required="true"
                      />
                    </div>
                  </div>

                  <div className="clearfix">
                    <div className="form-group">
                      <textarea
                        name="text"
                        ref={blogBodyRef}
                        class="input-md form-control"
                        rows="13"
                        placeholder="Blog Text"
                        required
                      ></textarea>
                    </div>
                  </div>

                  <div className="clearfix">
                    <div className="cf-center-col">
                      <div className="align-center pt-10">
                        <button
                          className="submit_btn btn btn-mod btn-medium btn-round"
                          id="request-withdraw-btn"
                        >
                          Create new blog
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </AuthCheck>
    </>
  );
}

export default function NewBlog() {
  return (
    <Suspense
      fallback={
        <div classname="page-loader">
          <div classname="loader">Loading...</div>
        </div>
      }
    >
      <NewBlogPage />
    </Suspense>
  );
}

import React from "react";

export default function Footer() {
  return (
    <>
      <hr className="mt-0 mb-0" />

      <section className="page-section" id="contact">
        <div className="container relative">
          <h2 className="section-title font-alt mb-70 mb-sm-40">
            Contact us for any support queries
          </h2>

          <div className="row mb-60 mb-xs-40">
            <div className="col-md-8 col-md-offset-2">
              <div className="row">
                <div className="col-sm-6 col-lg-4 pt-20 pb-20 pb-xs-0">
                  <div className="contact-item">
                    <div className="ci-icon">
                      <i className="fa fa-envelope"></i>
                    </div>
                    <div className="ci-title font-alt">Email</div>
                    <div className="ci-text">
                      <a href="mailto:info@thefxwave.com">info@thefxwave.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="page-section bg-dark footer pb-60">
        <div className="container">
          <div
            className="local-scroll mb-30 wow fadeInUp"
            data-wow-duration="1.5s"
          >
            <a href="#top">
              <img
                src="images/logos/wave-white.png"
                width="180"
                height="180"
                alt="Company logo"
              />
              <span className="sr-only">Scroll to the top of the page</span>
            </a>
          </div>

          <div className="footer-social-links mb-110 mb-xs-60 white">
            <div className="sr-only">Our profiles in social media:</div>
            <a
              href="https://www.facebook.com/TheFXWave"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook white"></i>
              <span className="sr-only white">Facebook profile</span>
            </a>
            <a
              href="https://www.instagram.com/thefxwave/"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram white"></i>
              <span className="sr-only white">Instagram profile</span>
            </a>
            <a
              href="https://www.youtube.com/channel/UCnPdS0pAtPdMLClv1RuWPwg"
              title="YouTube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-youtube-play white"></i>
              <span className="sr-only white">YouTube profile</span>
            </a>

            <a
              href="https://t.me/TheFxWave"
              title="Telegram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-telegram white"></i>
              <span className="sr-only white">Telegram profile</span>
            </a>
          </div>

          <div className="footer-text">
            <div className="footer-copy font-alt">&copy; FX Wave 2022 .</div>
          </div>
          <div className="footer-made">Ride The Markets Wave</div>
        </div>

        <div className="local-scroll">
          <a href="#top" className="link-to-top">
            <i className="fa fa-caret-up"></i>
          </a>
        </div>
      </footer>

      <a
        href="mailto:info@thefxwave.com?subject=The FxWave Support"
        target="_blank"
        className="support-button"
        rel="noopener noreferrer"
      >
        <i className="fa fa-envelope" aria-hidden="true"></i>
        <span>Help</span>
      </a>

      <a href="/subscriptions" className="buy-button" rel="noopener noreferrer">
        <i className="fa fa-shopping-cart" aria-hidden="true"></i>
        <span>Buy</span>
      </a>
    </>
  );
}
